import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { initializeMainApp } from '../../assets/js/app.js';

function Games() {
  const [searchQuery, setSearchQuery] = useState('');
  const [gameCards, setGameCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [gamesPerPage] = useState(6);
  const [gameIds, setGameIds] = useState([]);

  const [userInfo, setUserInfo] = useState({
    username: 'Loading...',
    profile_pic: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/Windows_10_Default_Profile_Picture.svg/2048px-Windows_10_Default_Profile_Picture.svg.png',
    discord_id: '',
    membership: '',
  });


  const logout = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/logout`, {
        method: 'GET',
        credentials: 'include',
      });

      if (response.ok) {
        document.cookie = 'Account_Session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        window.location.href = '/login';
      } else {
        const errorMessage = await response.text();
        throw new Error(errorMessage);
      }
    } catch (error) {
      console.error('Logout failed:', error);
      Swal.fire('Error', 'Logout failed', 'error');
    }
  };

  const deleteGame = (gameId) => {
    Swal.fire({
      title: 'Delete Game',
      text: 'Are you sure you want to delete this game?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel'
    }).then(result => {
      if (result.isConfirmed) {
        fetch(`${process.env.REACT_APP_API_URL}/api/deletegame`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            game_id: gameId
          }),
          credentials: 'include', // Include cookies in the request
        })
          .then(response => {
            if (response.ok) {
              Swal.fire('Success', 'Game Deleted', 'success');
              // Remove the deleted game from gameCards
              setCurrentPage(1)
              fetchTotalsGames(1);
            } else {
              console.error('Failed to delete game');
            }
          })
          .catch(error => {
            console.error(error);
          });
      }
    });
  };


  const loadGames = async (gameIds, currentPage) => {

    const newGameCards = [];

    // Calculate the indexes of games to display based on the current page and games per page
    const indexOfLastGame = currentPage * gamesPerPage;
    const indexOfFirstGame = indexOfLastGame - gamesPerPage;
    const gamesToDisplay = gameIds.slice(indexOfFirstGame, indexOfLastGame);

    for (let i = 0; i < gamesToDisplay.length; i++) {
      const gameId = gamesToDisplay[i];

      // Fetch game data and create game cards as before...
      try {
        const gameResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/game/${gameId}`, {
          method: 'GET',
          credentials: 'include', // Include cookies in the request
        });
        const gameData = await gameResponse.json();

        const gameName = gameData.data && gameData.data[0] ? gameData.data[0].name : 'Unknown Game';
        const { visits, playing, favoritedCount } = gameData.data && gameData.data[0] ? gameData.data[0] : { visits: 0, playing: 0, favoritedCount: 0 };

        const thumbnailResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/game/${gameId}/thumbnail`, {
          method: 'GET',
          credentials: 'include', // Include cookies in the request
        });
        const thumbnails = await thumbnailResponse.json();
        const thumbnailUrl = thumbnails.length > 0 ? thumbnails[0].imageUrl : '';

        const gameCard = (
          <div className="card m-b-30" id={`gameCard-${gameId}`} key={`gameCard-${gameId}`}>
            <div className="card-body">
              <h4 className="mt-0 header-title" id={`gameName-${i}`}>{gameName}</h4>
              <p className="text-success" id={`gameStats-${i}`}>Visits {visits} | Playing {playing} | Favorites {favoritedCount}</p>
              <div className="text-center" id={`gameThumbnail-${i}`}>
                <img src={thumbnailUrl} alt="Game Thumbnail" className="thumbnail-image" />
              </div>
              <div className="mt-4  flex-column align-items-center">
                <div className="mb-2">
                  <Link
                    to={`/configure?gameId=${gameId}`}
                    className="btn btn-primary btn-block waves-effect waves-light"
                  >
                    Configure
                  </Link>
                </div>
                <div className="mb-2">
                  <a href={`https://roblox.com/games/${gameId}`} target="_blank" className="btn btn-success btn-block waves-effect waves-light">View Game</a>
                </div>
                <div>
                  <button type="button" className="btn btn-danger btn-block waves-effect waves-light" onClick={() => deleteGame(gameId)}>Delete Game</button>
                </div>
              </div>
            </div>
          </div>
        );

        newGameCards.push(gameCard);
      } catch (error) {
        console.error(error);
      }
    }

    setGameCards(newGameCards);
    setLoading(false);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchTotalsGames(pageNumber);
    window.scrollTo(0, 0);
  };

  const [stats, setStats] = useState({
    points: 0,
    robux: 0,
    credits: 0,
  });


  const fetchTotalsGames = (currentPage) => {
    setLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}/api/totalgames`, {
      method: 'GET',
      credentials: 'include', // Include cookies in the request
    })
      .then(response => response.json())
      .then(data => {
        const gameIds = data.gameIds;
        console.log(gameIds)
        setGameIds(data.gameIds);
        loadGames(gameIds, currentPage);
      })
      .catch(error => console.log(error));
  };

  const effectRan = useRef(false);
  useEffect(() => {
    if (effectRan.current === false) {

      const fetchUserInfo = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user`, {
            method: 'GET',
            credentials: 'include', // Include cookies in the request
          });
          if (response.ok) {
            const data = await response.json();
            const userData = data.data;
            const userInfoData = {
              username: userData.username,
              profile_pic: userData.profile_pic,
              discord_id: userData.discord_id,
              membership: userData.membership,
            };
            setUserInfo(userInfoData);
            updateUserInfo(userInfoData);
            if (user.stats) {
              const userStats = {
                points: Intl.NumberFormat().format(user.stats.points) || 0,
                robux: Intl.NumberFormat().format(user.stats.robux) || 0,
                credits: user.stats.credits || 0,
              };
              setStats(userStats);
            } else {
              console.error('User stats not found');
            }
          } else {
            // Handle the case where the fetch request fails
            console.error('Error fetching user data:', response.statusText);
          }
        } catch (error) {
          console.error('Error during fetchUserInfo:', error);
        }
      };

      const updateUserInfo = (info) => {
        const profile_pic = document.getElementById('profile_pic');
        if (profile_pic) {
          profile_pic.src = info.profile_pic;
        }
      };

      fetchUserInfo();
      fetchTotalsGames(currentPage);
      initializeMainApp();
    }

    return () => {
      effectRan.current = true;
    };

  }, []);

  const performSearch = () => {
    const query = searchQuery.trim().toLowerCase();

    const gameCards = document.getElementsByClassName("card");
    for (let i = 0; i < gameCards.length; i++) {
      const gameNameElement = gameCards[i].querySelector(".header-title");
      const gameName = gameNameElement.textContent.toLowerCase();

      if (gameName.includes(query)) {
        gameCards[i].style.display = "block";
      } else {
        gameCards[i].style.display = "none";
      }
    }

    setSearchQuery('');
  };

  const totalPages = Math.ceil(gameIds.length / gamesPerPage);
  const visiblePages = 5;
  let startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2));
  let endPage = Math.min(totalPages, startPage + visiblePages - 1);

  if (endPage - startPage + 1 < visiblePages) {
    startPage = Math.max(1, endPage - visiblePages + 1);
  }

  const pageNumbers = [];
  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  const renderPagination = () => {
    const maxPagesToShow = 5; // Maximum number of page numbers to show at a time
    const startPage = Math.max(1, currentPage - 2); // Calculate the start page for the range
    const endPage = Math.min(startPage + maxPagesToShow - 1, totalPages); // Calculate the end page for the range

    const paginationItems = [];
    for (let i = startPage; i <= endPage; i++) {
      paginationItems.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? 'active' : ''}`}
          onClick={() => paginate(i)}
        >
          <a className="page-link" href="#">
            {i}
          </a>
        </li>
      );
    }

    return paginationItems;
  };

  const handleNextClick = () => {
    const nextPageSet = Math.min(currentPage + 5, totalPages);
    paginate(nextPageSet);
  };

  const handlePreviousClick = () => {
    const previousPageSet = Math.max(currentPage - 5, 1);
    paginate(previousPageSet);
  };

  return (
    <div id="wrapper">
      {/* Top Bar Start */}
      <div className="topbar">
        {/* LOGO */}
        <div className="topbar-left">
          <Link to="/" className="logo">
            <span className="logo-light">
              <i></i>Virizon Mgui{' '}
            </span>
            <span className="logo-sm">
              <i>V</i>
            </span>
          </Link>
        </div>
        <nav className="navbar-custom">
          <ul className="navbar-right list-inline float-right mb-0">
            {/* full screen */}
            <li className="dropdown notification-list list-inline-item d-none d-md-inline-block">
              <a className="nav-link waves-effect" href="#" id="btn-fullscreen">
                <i className="mdi mdi-arrow-expand-all noti-icon"></i>
              </a>
            </li>
            <li className="dropdown notification-list list-inline-item">
              <div className="dropdown notification-list nav-pro-img">
                <a
                  className="dropdown-toggle nav-link arrow-none nav-user"
                  data-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-haspopup="false"
                  aria-expanded="false"
                >
                  <img
                    src={userInfo.profile_pic}
                    alt="user"
                    className="rounded-circle"
                    id="profile_pic"
                  />
                </a>
                <div className="dropdown-menu dropdown-menu-right profile-dropdown ">
                  {/* item */}
                  <Link to="/settings" className="dropdown-item d-block">
                    <i className="mdi mdi-settings"></i> Settings
                  </Link>
                  <div className="dropdown-divider"></div>
                  <button className="dropdown-item text-danger" id="logoutButton" onClick={logout}>
                    <i className="mdi mdi-power text-danger"></i> Logout
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <ul className="list-inline menu-left mb-0">
            <li className="float-left">
              <button className="button-menu-mobile open-left waves-effect">
                <i className="mdi mdi-menu"></i>
              </button>
            </li>
          </ul>
        </nav>
      </div>
      <div className="left side-menu">
        <div className="slimscroll-menu" id="remove-scroll">
          {/*- Sidemenu */}
          <div id="sidebar-menu">
            {/* Left Menu Start */}
            <ul className="metismenu" id="side-menu">
              <li className="menu-title">
                <div className="row align-items-center">
                  <div className="col-3">
                    <div className="profile-pic">
                      <div className="count-indicator">
                        <img
                          className="thumb-sm rounded-circle mr-2"
                          id="profile_pic"
                          src={userInfo.profile_pic}
                          alt=""
                        />
                        <span className="count bg-success"></span>
                      </div>
                    </div>
                  </div>
                  <div className="col-9">
                    <div className="profile-name">
                      <h6 className="mb-0 font-weight-normal">{userInfo.username}</h6>
                      <span className="text-success">{userInfo.membership}</span>
                    </div>
                  </div>
                </div>
              </li>
              <li className="menu-title">Menu</li>
              <li>
                <Link to="/" className="waves-effect">
                  <i className="icon-calendar"></i>
                  <span>Dashboard</span>
                </Link>
              </li>
              <li className="menu-title">Publish Game</li>
              <li>
                <Link to="/publish" className="waves-effect">
                  <i className="mdi mdi-upload"></i>
                  <span>Publish</span>
                </Link>
              </li>
              <li className="menu-title">Mgui Settings</li>
              <li>
                <Link to="/addgame" className="waves-effect">
                  <i className="mdi mdi-gamepad-right"></i>
                  <span>Add Game</span>
                </Link>
                <Link to="/games" className="waves-effect">
                  <i className="mdi mdi-xbox-controller-menu"></i>
                  <span>Games</span>
                </Link>
                <Link to="/download" className="waves-effect">
                  <i className="fas fa-angle-double-down"></i>
                  <span>Download</span>
                </Link>
              </li>
              <li className="menu-title">Storage</li>
              <li>
                <Link to="/accounts" className="waves-effect">
                  <i className="far fa-address-book"></i>
                  <span>Checked Account</span>
                </Link>
                <Link to="/cookies" className="waves-effect">
                  <i className="fas fa-th-list"></i>
                  <span>Cookies</span>
                </Link>
              </li>
            </ul>
          </div>
          {/* Sidebar */}
          <div className="clearfix"></div>
        </div>
        {/* Sidebar -left */}
      </div>
      <div className="content-page">
        {/* Start content */}
        <div className="content">
          <div className="container-fluid">
            <div className="page-title-box">
              <div className="row align-items-center">
                <div className="col-sm-6">

                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-right">
                    <li className="breadcrumb-item">
                      <a href="javascript:void(0);">Virizon Games</a>
                    </li>
                    <li className="breadcrumb-item active">Game List</li>
                  </ol>
                </div>
              </div>
            </div>
            {/* START ROW */}
            <div className="row">
              <div className="col-md-12">
                <div className="input-group mb-3">
                  <input
                    type="text"
                    id="searchInput"
                    className="form-control"
                    placeholder="Search game"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <div className="input-group-append">
                    <button
                      id="searchButton"
                      className="btn btn-primary"
                      type="button"
                      onClick={performSearch}
                    >
                      Search
                    </button>

                  </div>
                </div>
              </div>
            </div>



            <div id="gamerows" className="row">
              {loading ? (
                <div className="col-lg-12 text-center">
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : (
                <>
                  {gameCards.map((card, i) => (
                    <div key={`gameCard-${i}`} className="col-lg-4">
                      {card}
                    </div>
                  ))}

                  <div className="col-md-12 text-center">
                    <div className="row mt-4">
                      <div className="col-12">
                        <nav aria-label="Page navigation example">
                          <ul className="pagination justify-content-center">
                            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                              <a className="page-link" href="#" aria-label="Previous" onClick={handlePreviousClick}>
                                <span aria-hidden="true">&laquo;</span>
                              </a>
                            </li>
                            {renderPagination()}
                            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                              <a className="page-link" href="#" aria-label="Next" onClick={handleNextClick}>
                                <span aria-hidden="true">&raquo;</span>
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>

                </>
              )}
            </div>

            {/* END ROW */}
          </div>
          {/* container-fluid */}
        </div>
        {/* content */}
        <footer className="footer"> © 2021 - 2022 Virizon Mgui </footer>
      </div>
      {/* End Right content here */}
    </div>
  );
}

export default Games;